import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const Name = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.6;
  color: ${({ color }) => color};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  max-width: 200px;
`

const ProfileImage = styled(GatsbyImage)`
  border-radius: 200px;
  width: 160px;
  margin: 15px;
  mask-image: radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const Title = styled.p`
  font-size: 1em;
  font-weight: 400;
  line-height: 1;
  color: ${props => props.theme.colors.gray};
`

const Profile = ({ name, position, image, color }) => (
  <Wrapper>
    <ProfileImage image={image}></ProfileImage>
    <Name color={color}>{name}</Name>
    <Title>{position}</Title>
  </Wrapper>
)

export default Profile

import React from 'react'
import styled from 'styled-components'

const Title = styled.header`
  font-size: 1.5em;
  line-height: 1em;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`

const ContactInfo = styled.a`
  color: ${props => props.theme.colors.white};
  font-size: 18px;
  line-height: 1.4;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 0 15px 0;
  max-width: 400px;
`

export default class ContactItem extends React.Component {
  render() {
    return (
      <Wrapper>
        <Title>{this.props.title}</Title>
        <ContactInfo href={this.props.link}>{this.props.info}</ContactInfo>
      </Wrapper>
    )
  }
}

import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Facebook from '../images/facebook.svg'
import LinkedIn from '../images/linkedin.svg'
import ContactForm from './ContactForm'
import ContactItem from './ContactItem'

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  padding: 40px;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding: 15px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  max-width: 1024px;
`

const ItemWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  max-width: 1000px;
  flex-wrap: wrap;
`

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 550px;
  flex-wrap: wrap;
  margin-left: 10px;
`

const Title = styled.h2`
  font-size: 2em;
  line-height: 1.1;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${({ color }) => color};
`

const Order = ({ langKey, backgroundColor, inputColor, color }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulContact {
        edges {
          node {
            address
            description
            eMailAddress
            language {
              key
            }
            phoneNumber
            title
            linkedInLink
            facebookLink
            phoneNumberTitle
            sendButtonTitle
            addressTitle
            socialTitle
            emailFeedback
          }
        }
      }
    }
  `)

  if (!data.allContentfulContact.edges) return null

  const { node } = data.allContentfulContact.edges.find(
    ({ node }) => node.language.key === langKey
  )
  const {
    title,
    address,
    description,
    eMailAddress,
    phoneNumber,
    phoneNumberTitle,
    sendButtonTitle,
    socialTitle,
    addressTitle,
    linkedInLink,
    facebookLink,
  } = node

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <ContentWrapper>
        <TextWrapper>
          <Title color={color} id="order">
            {title}
          </Title>
          <p>{description}</p>
        </TextWrapper>
        <ItemWrapper>
          <ContactForm
            inputColor={inputColor}
            sendButtonTitle={sendButtonTitle}
            eMailAddress={eMailAddress}
          />

          <ContactWrapper>
            <ContactItem
              title="Email"
              info={eMailAddress}
              link={`mailto:${eMailAddress}`}
            />
            <ContactItem
              title={addressTitle}
              info={address}
              link={`https://maps.google.com/?q=${address}`}
            />
            <ContactItem
              title={phoneNumberTitle}
              info={phoneNumber}
              link={`tel:${phoneNumber}`}
            />
            <ContactItemWrapper>
              <ContactTitle>{socialTitle}</ContactTitle>
              <SocialWrapper>
                <a target="_blank" href={facebookLink}>
                  <SocialImage src={Facebook} alt="Facebook" />
                </a>
                <a target="_blank" href={linkedInLink}>
                  <SocialImage src={LinkedIn} alt="LinkedIn" />
                </a>
              </SocialWrapper>
            </ContactItemWrapper>
          </ContactWrapper>
        </ItemWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

const SocialImage = styled.img`
  width: 45px;
  margin-right: 10px;
  &:hover {
    transform: scale(1.08);
    transition: all 0.3s;
  }
`

const ContactTitle = styled.header`
  font-size: 1.5em;
  line-height: 2em;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  max-width: 100px;
`

const ContactItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 15px 15px 0;
  max-width: 200px;
`

export default Order

module.exports = {
  siteTitle: 'MotMould - 3D Printing & Scanning',
  siteTitleAlt: 'MotMould - 3D Printing & Scanning', // This allows an alternative site title for SEO schema.
  publisher: 'Nándor Motruk', // Organization name used for SEO schema
  siteDescription:
    'High quality 3D printing and molding solutions for your company',
  siteUrl: 'https://motmould.com/', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  shortTitle: 'MotMould', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 1440, // Change to the width of your default share image
  shareImageHeight: 960, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2020 MotMould', // Copyright string for the RSS feed
}

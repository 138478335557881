import React from 'react'
import styled from 'styled-components'

const Form = styled.form`
  max-width: 550px;
  margin: 0;
  margin-bottom: 30px;
  margin-right: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
    border: none;
    outline: none;
    background: ${props => props.inputColor};
    color: ${props => props.theme.colors.white};
    border-radius: 2px;
    padding: 1em;
    &::-webkit-input-placeholder {
      color: #cecece;
    }
    &::-moz-placeholder {
      color: #cecece;
    }
    &:-ms-input-placeholder {
      color: #cecece;
    }
    &:-moz-placeholder {
      color: #cecece;
    }
    &:required {
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? '.8' : '0')};
    visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
  }
`

const Name = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.small}) {
    width: 49%;
  }
`

const Email = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.small}) {
    width: 49%;
  }
`

const Phone = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
`

const Message = styled.textarea`
  width: 100%;
  margin: 0 0 1em 0;
  line-height: 1.6;
  min-height: 280px;
  resize: vertical;
`

const Submit = styled.button`
  color: ${props => props.theme.colors.white};
  min-height: 40px;
  min-width: 100px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.2s all;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.colors.white};
  &:hover {
    color: ${props => props.theme.colors.darkGray};
    background-color: ${props => props.theme.colors.white};
  }
`

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      _replyto: '',
      phone: '',
      message: '',
      showModal: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <Form
        name="contact"
        action={`https://formspree.io/f/xoqporaa`}
        method="POST"
        overlay={this.state.showModal}
        onClick={this.closeModal}
        inputColor={this.props.inputColor}
      >
        <Name
          name="name"
          type="text"
          placeholder="Full Name"
          value={this.state.name}
          onChange={this.handleInputChange}
          required
        />
        <Email
          name="_replyto"
          type="email"
          placeholder="Email"
          value={this.state.email}
          onChange={this.handleInputChange}
          required
        />
        <Phone
          name="phone"
          type="tel"
          placeholder="Phone number"
          value={this.state.phone}
          onChange={this.handleInputChange}
          required
        />
        <Message
          name="message"
          type="text"
          placeholder="Message"
          value={this.state.message}
          onChange={this.handleInputChange}
          required
        />
        <Submit name="submit" type="submit">
          {this.props.sendButtonTitle}
        </Submit>
      </Form>
    )
  }
}

export default ContactForm

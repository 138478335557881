import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Profile from './Profile'

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px;
  width: 100%;
  background: #fbfbfb;
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding: 15px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 30px;
  width: 100%;
  max-width: 1024px;
  background: #fbfbfb;
  @media (max-width: 900px) {
    justify-content: center;
  }
`

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const Title = styled.h2`
  font-size: 2em;
  line-height: 1.8;
  font-weight: 700;
  color: ${props => props.color};
`

const Text = styled.p`
  font-size: 1em;
  line-height: 1.4;
  font-weight: 400;
  color: ${props => props.theme.colors.gray};
`

const ChooseUs = ({ langKey, color }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulChooseUs {
          edges {
            node {
              language {
                key
              }
              description
              firstMemberName
              firstMemberPhoto {
                gatsbyImageData
              }
              secondMemberName
              secondMemberTitle
              firstMemberTitle
              title
              secondMemberPhoto {
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  )

  const chooseUsData = data.allContentfulChooseUs.edges.find(
    ({ node }) => node.language.key === langKey
  )

  if (!chooseUsData) return null

  const {
    node: {
      title,
      description,
      firstMemberName,
      firstMemberPhoto,
      firstMemberTitle,
      secondMemberName,
      secondMemberTitle,
      secondMemberPhoto,
    },
  } = chooseUsData

  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title color={color} id="choose-us">
            {title}
          </Title>
          <Text>{description}</Text>
        </TextWrapper>
        <ProfileWrapper>
          <Profile
            name={firstMemberName}
            position={firstMemberTitle}
            image={firstMemberPhoto.gatsbyImageData}
            color={color}
          />
          <Profile
            name={secondMemberName}
            position={secondMemberTitle}
            image={secondMemberPhoto.gatsbyImageData}
            color={color}
          />
        </ProfileWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default ChooseUs
